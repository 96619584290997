<template>
  <header class="header_container">
    <router-link to="/" class="container_logo">
      <img class="logo" src="../assets/img/logo_v2-04.svg" alt="" />
    </router-link>

    <div class="container_hamburguer">
      <img
        v-if="menuHamburguer"
        @click="trocaMenu"
        class="btn_hamburguer icon_hamburguer"
        src="../assets/img/menu-fechado.svg"
        alt=""
      />
      <img
        v-else
        @click="trocaMenu"
        class="btn_hamburguer icon_hamburguer"
        src="../assets/img/menu-aberto.svg"
        alt=""
      />
    </div>

    <transition mode="out-in">
      <nav class="container_nav" key="aberto" v-show="!menuHamburguer">
        <router-link
          v-for="(value, key) in base"
          :key="key"
          to="{{ value.url }}"
          >{{ value.titulo }}
        </router-link>
      </nav>
    </transition>
  </header>
</template>

<script>
import base from "@/database/menu.js";
export default {
  name: "HeaderView",
  data() {
    return {
      base,
      menuHamburguer: true,
    };
  },
  methods: {
    trocaMenu: function () {
      if (this.menuHamburguer == true) {
        this.menuHamburguer = false;
        console.log("Cliquei no menu 1" + this.menuHamburguer);
      } else {
        this.menuHamburguer = true;
        console.log("Cliquei no menu 2" + this.menuHamburguer);
      }
    },
  },
};
</script>

<style>
.v-enter {
  opacity: 0.7;
}
.v-leave-to {
  opacity: 0.7;
}

.v-enter-active {
  transition: all 0.1s;
}
.v-leave-active {
  transition: all 0.05s ease;
  translate: 50px;
}
@media (max-width: 800px) {
  .header_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
  }

  .logo {
    padding-top: 5px;
    min-width: 250px;
    max-width: 500px;
  }

  .container_nav {
    position: relative;
    left: 0;
    right: 0;
    grid-column: 1 /-1;
  }

  .container_hamburguer {
    display: flex;
    justify-content: end;
  }
  .icon_hamburguer {
    max-width: 50px;
  }

  .container_nav {
    display: grid;
    text-align: center;
  }
  .container_nav a {
    padding: 45px;
    border-bottom: 1px solid #d2d2d2;
  }
}

@media (min-width: 801px) {
  .link {
    color: brown;
  }
}
</style>
