<template>
  <div>
    <img src="../assets/img/banner/bannerap.jpg" alt="" />
    <img src="../assets/img/banner/banner2.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "BannerView",

  data() {
    return {};
  },
};
</script>

<style scoped>
img {
  margin-bottom: -4px;
}
</style>
