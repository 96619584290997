<template>
  <div class="container_transmissao">
    <img src="../assets/img/transmissao/p1.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
img {
  max-width: 100%;
}
</style>
