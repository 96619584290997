export default {
  home: {
    titulo: "Home",
    url: "/",
  },
  endereco: {
    titulo: "sobre Nós",
    url: "/sobrenos",
  },
  cardapio: {
    titulo: "Conheça nosso cardápio",
    url: "/cardapio",
  },
  franquia: {
    titulo: "Seja um franqueado",
    url: "/franquia",
  },
  licenca: {
    titulo: "Seja um licenciado",
    url: "/licenciado",
  },
  fale_conosco: {
    titulo: "Fale com nossa Equipe",
    url: "/faleconosco",
  },
  transmissao: {
    titulo: "Transmissão",
    url: "/transmissão",
  },
};
