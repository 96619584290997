<template>
  <footer class="container_footer">
    <nav class="subcontaine1">
      <template v-for="(item, index) in database" :key="index">
        <router-link to="{{ item.url }}" class="link_footer">{{
          item.titulo
        }}</router-link>
      </template>
    </nav>

    <div class="subcontaine2 logo_footer">
      <img class="foto_logo_footer" src="../assets/img/logo_loja.png" alt="" />
    </div>

    <div class="subcontaine3">
      <p class="ultimo_paragrafo">
        @2024 | Click Sorvetes Política de privacidade. Todos os direitos
        reservados.
      </p>
    </div>
  </footer>
</template>

<script>
import database from "@/database/menu.js";

export default {
  name: "FooterView",
  data() {
    return {
      database: database,
    };
  },
  methods: {
    puxaDados() {
      this.database.forEach((element) => {
        console.log(element);
      });
    },
  },
};
</script>

<style scoped>
.container_footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  border-top: 10px solid #f60d60;
}
.subcontaine1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.subcontaine1 a {
  display: block;
  padding: 0.625rem 0;
  justify-self: center;
  width: 100%;
  border-bottom: 0.5px solid #f60d60;
}
.subcontaine2 {
  max-width: 9.375rem;
}

.subcontaine3 {
  margin-top: 1.875rem;
  text-align: center;
  grid-column: 1 / -1;
}
</style>
